<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>额度明细</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 查询 -->
      <!-- <div
        style="margin-bottom: 20px; display: flex; align-items: center; flex-wrap: wrap;"
      >
        <label style="margin: 10px;" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width:200px;"
            size="small"
            @change="pkIdChange"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px;" for=""
          >商家名称：
          <el-select
            v-model="query.shopId"
            filterable
            placeholder="请输入商家名称"
            style="width: 150px"
            size="small"
            clearable
          >
            <el-option
              v-for="item in businessNameSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="shopBuyLogListcx"
          >查询
        </el-button>
        <el-button size="small" @click="shopBuyLogListcz()">重置</el-button>
      </div> -->
      <!-- 点击添加显示对话框 -->
      <!-- <el-button type="primary" size="small " v-if="isShowbtn" icon="el-icon-plus" @click="isShowAdd = true">新增</el-button> -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        :loading="isShowImprot"
        @click="improtClick"
        >导出
      </el-button>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="shopId"
            label="商家名称"
          >
            <template slot-scope="scope">
              <span>{{ findQuery(scope.row.shopId) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="title"
            label="标题"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="flow"
            label="资金留向"
          >
            <template slot-scope="scope">
              <span>
                {{ translate(scope.row.flow) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="money"
            label="发生金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="returnOrderNo"
            label="回退订单号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="returnPlateNo"
            label="回退车牌号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="returnCouponName"
            label="回退优惠券名称"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalCouponMoney"
            label="总可用额度"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="购买时间"
            sortable
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="remarks"
            label="备注"
          ></el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-s:page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  shopMoneyLogList,
  findByShopId,
  exportShopBuyLog,
} from '@/api/coupon.js'
import { findPkInfoSelect, businessList } from '@/api/Parkinglot.js'
import { sysDictData } from '@/api/dictionaries.js'

export default {
  data() {
    return {
      businessNameSelect: [],
      isShowImprot: false,
      loading: false,
      isShowAdd: false,
      isShowData: false,
      isShowbtn: true,
      tableData: [],
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pkId: undefined, // 停车场id
        shopId: undefined, // 商户名称
        sortField: 'create_time',
        sortType: 'desc',
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      payment: [],
    }
  },
  created() {},
  activated() {
    this.sysDictData()
    if (this.$cookies.get('user')) {
      this.isShowbtn = true
    } else {
      this.isShowbtn = false
    }
    this.loading = true
    this.businessList()
    this.query.shopId = this.$route.query.shopId
    this.query.pkId = this.$route.query.pkId
    shopMoneyLogList(this.query, { pk_id: this.query.pkId }).then((res) => {
      this.tableData = []
      this.tableData = res.data.data.list
      this.total = res.data.data.total
      this.loading = false
    })
  },
  methods: {
    sysDictData() {
      // 字典
      sysDictData({ typeId: 104 }).then((res) => {
        //支付方式
        this.payment = res.data.data.map((el) => {
          return { name: el.name, id: el.code }
        })
      })
    },
    translate(value) {
      const item = this.payment.find((item) => item.id == value)
      return item ? item.name : ''
    },
    findQuery(code) {
      // 翻译
      const item = this.businessNameSelect.find((item) => item.id == code)
      return item ? item.name : ''
    },
    //停车场选择
    pkIdChange() {
      this.businessList()
    },
    //   商家列表
    businessList() {
      businessList({ pkId: this.$route.query.pkId }).then((res) => {
        this.businessNameSelect = res.data.data.map((el) => {
          return { name: el.shopName, id: el.id }
        })
      })
    },
    improtClick() {
      // 导出
      this.isShowImprot = true
      exportShopBuyLog(this.query).then((res) => {
        if (res.data.code === '200') {
          window.open(res.data.data)
        }
        this.isShowImprot = false
      })
    },
    shopBuyLogList() {
      // this.businessList();
      // 列表
      shopMoneyLogList(this.query, { pk_id: this.query.pkId }).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        this.isShowData = true
        this.loading = false
      })
    },
    shopBuyLogListcx() {
      // 查询
      this.query.current = 1
      this.shopBuyLogList()
    },
    shopBuyLogListcz() {
      // 重置
      this.query.pkId = this.PkInfoSelect[0].id
      this.query.shopName = ''
      this.shopBuyLogList()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pkId = this.PkInfoSelect[0].id
          this.shopBuyLogList()
        }
      })
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.shopBuyLogList()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.shopBuyLogList()
    },
  },
}
</script>
<style lang="scss" scoped></style>
